<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitNewLegal">
      <div class="form-group">
        <label>{{$t('views.franchise.franchise')}}</label>
        <select
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newLegal.franchiseId.$error }"
            v-model="newLegal.franchiseId"
        >
          <option
              v-for="item in franchises"
              :key="item.id"
              :value="item.id"
          >
            {{item.franchise_name}}
          </option>
        </select>
        <div v-if="submitted && $v.newLegal.franchiseId.$error" class="invalid-feedback">
          <span v-if="!$v.newLegal.franchiseId.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newLegal.franchiseId.minValue">
            {{$t('validatePhrases.chooseFranchise')}}
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="newLegal_name">{{$t('views.franchise.legal_person_name')}}</label>
        <input
            id="newLegal_name"
            v-model="newLegal.legalName"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newLegal.legalName.$error }"
        />
        <div v-if="submitted && $v.newLegal.legalName.$error" class="invalid-feedback">
          <span v-if="!$v.newLegal.legalName.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newLegal.legalName.minLength">
            {{$t('validatePhrases.minLength')}}: 3
          </span>
          <span v-if="!$v.newLegal.legalName.maxLength">
            {{$t('validatePhrases.maxLength')}}: 30
          </span>
        </div>
      </div>
      <button class="btn btn-sm btn-success" type="submit">{{$t('popularWords.add')}}</button>
    </form>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{$t('views.franchise.legal_person_list')}}</h4>

        <div class="row mt-3">
          <div class="col-12">
            <table class="table table-hover mb-0">
              <tbody>
              <tr v-for="(item, index) in list" :key="index">
                <th>
                  <div><b>{{item.franchise.franchise_name}}</b> -> {{item.legal_person_name}}</div>
                </th>
                <th>
                  <div class="button-items text-right">
                    <div @click="deleteLegal(item.id)" class="btn btn-sm btn-danger">{{$t('popularWords.delete')}}</div>
                  </div>
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <popup
        :closeButton="deletePopup.closeButton"
        :actionButton="deletePopup.actionButton"
        :actionClass="deletePopup.actionClass"
        :show="deletePopup.show"
        @closePopup="closeDeletePopup"
        @actionPopup="deletePopupSubmit"
    >
      <div slot="header">{{$t('views.franchise.delete_legal')}}</div>
      <div slot="body">
        {{$t('views.franchise.delete_legal_text')}}
      </div>
    </popup>

  </div>
</template>

<script>


import axios from "axios";
import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
import Popup from "@/components/Popup";

export default {
  name: "LegalPerson",
  components: {Popup},
  data(){
    return {
      list: [],
      deletedId: 0,
      newLegal: {
        franchiseId: '',
        legalName: ''
      },
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      submitted: false,
    }
  },
  props: {
    franchises: Array
  },
  watch: {
    list: function(val){
      this.$emit('updateList', val);
    }
  },
  computed: {

  },
  validations: {
    newLegal: {
      franchiseId: { required, minValue: minValue(1) },
      legalName: { required, minLength: minLength(3), maxLength: maxLength(250) }
    }
  },
  methods: {
    submitNewLegal(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newLegal.$touch();
      if(!this.$v.newLegal.$invalid){
        let formData = new FormData();
        formData.append("franchise_id", this.newLegal.franchiseId);
        formData.append("legal_name", this.newLegal.legalName);

        axios
            .post(`/v1/legal-person/add`, formData)
            .then(resp => {
              this.list = resp.data;
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deleteLegal(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedId);

      axios
          .post(`/v1/legal-person/delete`, formData)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
            this.getAll();
            this.closeDeletePopup();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .get(`/v1/legal-person/get-all`)
          .then(resp => {
            this.list = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }

};
</script>

<style scoped lang="css">

</style>
