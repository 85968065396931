<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.franchise.franchise')}}</h4>

            <franchise @updateList="setFranchiseList"></franchise>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.franchise.legal_persons')}}</h4>

            <LegalPerson
              :franchises="franchiseList"
              @updateList="setLegalList"
            ></LegalPerson>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('views.franchise.partnership')}}</h4>

            <LegalRelations
              :legalList="legalList"
            ></LegalRelations>
          </div>
        </div>
      </div>


    </div>
  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Franchise from "@/components/management/franchise/Franchise";
import LegalPerson from "@/components/management/franchise/LegalPerson";
import LegalRelations from "@/components/management/franchise/LegalRelations";

// import {
//   required,
//   minLength,
//   maxLength
// } from "vuelidate/lib/validators";
// import axios from "axios";

/**
 * Rbac component
 */
export default {
  components: {
    LegalRelations,
    LegalPerson,
    Franchise,
    Layout,
    PageHeader,
  },
  data() {
    return {
      franchiseList: [],
      legalList: [],
      submitted: false,
      title: this.$t('views.franchise.franchise'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.franchise'),
          active: true
        }
      ]
    };
  },
  validations: {

  },
  computed: {

  },
  methods: {
    setFranchiseList(val){
      this.franchiseList = val;
    },
    setLegalList(val){
      this.legalList = val;
    }
  },
  created() {

  }
};
</script>
