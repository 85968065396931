<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitNewRelation">
      <div class="form-group">
          <label>{{$t('views.franchise.main_legal')}}</label>
        <select
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newRelation.legalMain.$error }"
            v-model="newRelation.legalMain"
        >
          <option
              v-for="item in legalList"
              :key="item.id"
              :value="item.id"
          >
            {{item.legal_person_name}}
          </option>
        </select>
        <div v-if="submitted && $v.newRelation.legalMain.$error" class="invalid-feedback">
          <span v-if="!$v.newRelation.legalMain.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newRelation.legalMain.minValue">
            {{$t('validatePhrases.requiredField')}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <label>{{$t('views.franchise.slave_legal')}}</label>
        <select
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newRelation.legalMain.$error }"
            v-model="newRelation.legalSlave"
        >
          <option
              v-for="item in legalList"
              :key="item.id"
              :value="item.id"
          >
            {{item.legal_person_name}}
          </option>
        </select>
        <div v-if="submitted && $v.newRelation.legalSlave.$error" class="invalid-feedback">
          <span v-if="!$v.newRelation.legalSlave.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newRelation.legalSlave.minValue">
            {{$t('validatePhrases.requiredField')}}
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="newRelation_percent">{{$t('views.franchise.percent_to_partner')}}</label>
        <input
            id="newRelation_percent"
            v-model="newRelation.defaultSlavePercent"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.newRelation.defaultSlavePercent.$error }"
        />
        <div v-if="submitted && $v.newRelation.defaultSlavePercent.$error" class="invalid-feedback">
          <span v-if="!$v.newRelation.defaultSlavePercent.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.newRelation.defaultSlavePercent.maxValue">
            {{$t('validatePhrases.maxValue')}}: 100
          </span>
        </div>
      </div>

      <button class="btn btn-sm btn-success" type="submit">{{$t('popularWords.add')}}</button>
    </form>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{$t('views.franchise.partnership_list')}}</h4>

        <div class="row mt-3">
          <div class="col-12">
            <table class="table table-hover mb-0">
              <tbody>
              <tr v-for="(item, index) in list" :key="index">
                <th>
                  <div>
                    <b>{{item.legalMain.legal_person_name}}</b> ->
                    {{item.legalSlave.legal_person_name}} ({{item.default_slave_percent}}%)
                  </div>
                </th>
                <th>
                  <div class="button-items text-right">
                    <div @click="deleteRelation(item.id)" class="btn btn-sm btn-danger">{{$t('popularWords.delete')}}</div>
                  </div>
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>

    <popup
        :closeButton="deletePopup.closeButton"
        :actionButton="deletePopup.actionButton"
        :actionClass="deletePopup.actionClass"
        :show="deletePopup.show"
        @closePopup="closeDeletePopup"
        @actionPopup="deletePopupSubmit"
    >
      <div slot="header">{{$t('views.franchise.delete_partnership')}}</div>
      <div slot="body">
        {{$t('views.franchise.delete_partnership_text')}}
      </div>
    </popup>

  </div>
</template>

<script>


import axios from "axios";
import { maxValue, minValue, required} from "vuelidate/lib/validators";
import Popup from "@/components/Popup";

export default {
  name: "LegalRelations",
  components: {Popup},
  data(){
    return {
      list: [],
      deletedId: 0,
      newRelation: {
        legalMain: 0,
        legalSlave: 0,
        defaultSlavePercent: ''
      },
      deletePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      submitted: false,
    }
  },
  props: {
    legalList: Array
  },
  watch: {

  },
  computed: {

  },
  validations: {
    newRelation: {
      legalMain: { required, minValue: minValue(1) },
      legalSlave: { required, minValue: minValue(1) },
      defaultSlavePercent: { required, maxValue: maxValue(100) }
    }
  },
  methods: {
    submitNewRelation(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newRelation.$touch();
      if(!this.$v.newRelation.$invalid){
        let formData = new FormData();
        formData.append("legal_main_id", this.newRelation.legalMain);
        formData.append("legal_slave_id", this.newRelation.legalSlave);
        formData.append("slave_percent", this.newRelation.defaultSlavePercent);

        axios
            .post(`/v1/legal-relations/add`, formData)
            .then(resp => {
              this.list = resp.data;
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 2,
                color: "success"
              });
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deleteRelation(id){
      this.deletedId = id;
      this.deletePopup.show = true;
    },
    closeDeletePopup(){
      this.deletePopup.show = false;
    },
    deletePopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedId);

      axios
          .post(`/v1/legal-relations/delete`, formData)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.deleted'),
              time: 2,
              color: "success"
            });
            this.getAll();
            this.closeDeletePopup();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .get(`/v1/legal-relations/get-all`)
          .then(resp => {
            this.list = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }

};
</script>

<style scoped lang="css">

</style>
